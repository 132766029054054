<footer *ngIf="mostrar" class="footer backgroud pt-[117px] pb-[139px] text-center md:flex md:pt-[24px] md:pb-[36px] md:pl-[50px] md:pr-[50px] md:items-center">
    <div class="footer__container one md:flex-1">
        <div class="logo-container">
            <div class="logo">
                <div class="unacem-container">
                <div class="unacem">
                    <img src="../../../assets/svg/logo/imagen.svg" style="width: 13px; height: 14px;"/>
                    <img src="../../../assets/svg/logo/U-unacem.svg" style="width: 7px; height: 8px; margin-left: 2px;"/>
                    <img src="../../../assets/svg/logo/N-unacem.svg" style="width: 7px; height: 8px;"/>
                    <img src="../../../assets/svg/logo/A-unacem.svg" style="width: 8px; height: 8px;"/>
                    <img src="../../../assets/svg/logo/C-unacem.svg" style="width: 8px; height: 8px;"/>
                    <img src="../../../assets/svg/logo/E-unacem.svg" style="width: 7px; height: 8px;"/>
                    <img src="../../../assets/svg/logo/M-unacem.svg" style="width: 8px; height: 8px;"/>
                </div>
                </div>
                <div class="cantera" style="display: flex; padding-top: 3px;">
                <img src="../../../assets/svg/logo/C-cantera.svg" style="margin-right: -1px;"/>
                <img src="../../../assets/svg/logo/A-cantera.svg" style="padding-right: 1.1px;"/>
                <img src="../../../assets/svg/logo/N-cantera.svg" style="padding-right: 1.5px;"/>
                <img src="../../../assets/svg/logo/T-cantera.svg" style="padding-right: 1px;"/>
                <img src="../../../assets/svg/logo/E-cantera.svg" style="padding-right: 1.5px;"/>
                <img src="../../../assets/svg/logo/R-cantera.svg" />
                <img src="../../../assets/svg/logo/A-cantera.svg" style="padding-left: -0.5px;"/>
                </div>
            </div>
        </div>
        <p class="text-white text-[24px] leading-[160%] md:text-[14px] md:w-[170px]">© 2020 UNACEM</p>
    </div>
    <div class="footer__container two md:flex-1">
        <p class="text-white pt-[52px] pb-[70px] text-[30px] leading-[160%] md:text-[20px]">UNACEM PERÚ S.A</p>
    </div>
    <div class="footer__container three text-[24px] md:flex-1 md:text-[14px] md:text-right relative">
        
        <div style="padding-top: 15px;">
        <p class="text-white leading-[160%]">Av Atocongo 2440</p>
        <p class="text-white leading-[160%]">Villa Maria del Triunfo. Lima, Perú</p>
        <p class="text-white leading-[160%]"><a href="tel:905452493" target="_blank" rel="noopener noreferrer">T (511) 905 452 493</a></p>
        </div>
    </div>
</footer>